import React, { useState, useRef, useEffect} from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import { useDispatch, useSelector } from "react-redux";
import { qTitleStore, chatBotStore } from "../redux/store";


const API_URL = "http://quesbot-api-alb-1384125209.eu-west-2.elb.amazonaws.com";

const ChatBot = ({ isPdfOpen,currentQuestion}) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [qState, setqState] = useState("[]");
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [botPrompts, setBotPrompts] = useState([
    {
      text: "Does this organisation deal with a lot of consumers?",
    },
    {
      text: "Does this organisation deal with a lot of businesses?",
    },
    {
      text: "Does this organisation deal with a lot of public sector customers or suppliers?",
    },
    {
      text: "Does this organisation deal with a lot of not-for-profit customers or suppliers?",
    },
  ]);

  const [showBotPrompts, setShowBotPrompts] = useState(false);
  const specialCases = {
    3: 1,
    7: 5,
    15: 12,
    19: 17,
    22: 20,
    25: 23,
    30: 27,
    33: 31,
    38: 36,
    41: 39,
    46: 43,
    48: 46,
    51: 49,
    58: 56,
  };

  useEffect(() => {
    let targetQuestion = currentQuestion;

    // Check if the current question has a special case
    if (specialCases[currentQuestion]) {
      targetQuestion = specialCases[currentQuestion];
    }

    // Access state related to the target question
    setqState(qTitleStore.getState()[targetQuestion]) // Adjust if your state structure is different


    if (chatBotStore.getState().length !== 0) {
      setMessages(chatBotStore.getState());
      chatBotStore.getState().forEach((element) => {
        if (element.text.includes("1 file(s) uploaded")) {
          setShowBotPrompts(true);
        }
      });
    }
  }, [currentQuestion]);

  // useEffect(() => {
  //   if(messages.length > 0){
  //     setShowBotPrompts(true)
  //   }else{
  //     setShowBotPrompts(false)
  //   }
  // }, [currentQuestion]);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = async (messageText) => {
    const trimmedMessage = messageText || inputMessage.trim();
    if (trimmedMessage !== "") {
      const userMessage = { text: trimmedMessage, sender: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      chatBotStore.dispatch({type: 'ADD_MESSAGE', payload: [trimmedMessage, "user"]});
      setInputMessage("");

      try {
        const response = await axios.post(`${API_URL}/chat`, {
          question: trimmedMessage,
          chat_history: chatHistory,
        });

        const botMessage = { text: response.data.answer, sender: "bot" };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        chatBotStore.dispatch({type: 'ADD_MESSAGE', payload: [response.data.answer,"bot"]});
        // Update chat history
        setChatHistory((prevHistory) => [
          ...prevHistory,
          [trimmedMessage, response.data.answer],
        ]);

      } catch (error) {
        console.error("Error sending message to API:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Sorry, I couldn't process your request.", sender: "bot" },
        ]);
      }
    }
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.type === "application/pdf");

    if (validFiles.length !== files.length) {
      setMessages((prev) => [
        ...prev,
        { text: "Only PDF files are allowed.", sender: "system" },
      ]);
    } else {
      setMessages((prev) => [
        ...prev,
        { text: `${validFiles.length} file(s) uploaded`, sender: "user" },
      ]);
      chatBotStore.dispatch({type: 'ADD_MESSAGE', payload: [`${validFiles.length} file(s) uploaded`,"user"]});
      const formData = new FormData();
      validFiles.forEach((file) => formData.append("files", file));

      try {
        const response = await axios.post(`${API_URL}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setMessages((prev) => [
          ...prev,
          { text: response.data.message, sender: "bot" },
        ]);
        chatBotStore.dispatch({type: 'ADD_MESSAGE', payload: [response.data.message,"bot"]});
        
        // Display the bot prompts as clickable buttons
        setShowBotPrompts(true);
      } catch (error) {
        console.error("Error uploading files:", error);
        setMessages((prev) => [
          ...prev,
          { text: "Sorry, I couldn't upload the files.", sender: "bot" },
        ]);
        chatBotStore.dispatch({type: 'ADD_MESSAGE', payload: ["Sorry, I couldn't upload the files.","bot"]});
      }
    }
  };

  const handleBotPromptClick = (promptText) => {
    handleSendMessage(promptText);
    setShowBotPrompts(false);
  };

  if (!isPdfOpen) return null;

  return (
    <Paper
      elevation={3}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        style={{
          color: "#333",
          borderBottom: "1px solid #e5fffc",
          paddingBottom: "8px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        ChatBot Assistant
      </Typography>
      <List
        style={{
          flexGrow: 1,
          overflow: "auto",
          marginBottom: "16px",
          padding: "0px",
        }}
      >
        {messages.map((message, index) => (
          <ListItem
            key={index}
            style={{
              justifyContent:
                message.sender === "user" ? "flex-end" : "flex-start",
              wordWrap: "break-word",
              marginBottom: "8px",
            }}
          >
            <Paper
              elevation={1}
              style={{
                padding: "8px 12px",
                maxWidth: "70%",
                backgroundColor:
                  message.sender === "user"
                    ? "#e3f2fd"
                    : message.sender === "bot"
                    ? "#fff"
                    : "#f0f0f0",
                borderRadius:
                  message.sender === "user"
                    ? "20px 20px 0 20px"
                    : "20px 20px 20px 0",
              }}
            >
              <ListItemText
                primary={message.text}
                style={{
                  color: message.sender === "system" ? "#666" : "#333",
                  fontStyle: message.sender === "system" ? "italic" : "normal",
                }}
              />
            </Paper>
          </ListItem>
        ))}

        {/* Render bot prompts as clickable buttons when file is uploaded */}
        {/* {showBotPrompts &&
          qTitleStore.getState() ===
            "What proportion of revenue comes from each of the categories?" && (
              <>
              <Button
                      
                      variant="contained"
                      onClick={() => handleBotPromptClick(qTitleStore.getState())}
                      style={{
                        backgroundColor: "#e0f7fa",
                        color: "#006064",
                        marginBottom: "10px",
                      }}
                    >
                      {qTitleStore.getState()}
                    </Button>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {botPrompts.map((prompt, index) => (
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => handleBotPromptClick(prompt.text)}
                  style={{
                    backgroundColor: "#e0f7fa",
                    color: "#006064",
                    marginBottom: "10px",
                  }}
                >
                  {prompt.text}
                </Button>
              ))}
            </div>
            </>
          )} */}
         
         {showBotPrompts && qTitleStore.getState() === "What proportion of revenue comes from each of the categories?" && (
          <>
            <Button
              variant="contained"
              onClick={() => handleBotPromptClick(qTitleStore.getState())}
              style={{
                backgroundColor: "#e0f7fa",
                color: "#006064",
                marginBottom: "10px",
              }}
            >
              {qState}
            </Button>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {botPrompts.map((prompt, index) => (
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => handleBotPromptClick(prompt.text)}
                  style={{
                    backgroundColor: "#e0f7fa",
                    color: "#006064",
                    marginBottom: "10px",
                  }}
                >
                  {prompt.text}
                </Button>
              ))}
            </div>
          </>
)}


 


        {showBotPrompts && 
        <>  
          <div style={{  color: "#006064" }}>
          <Button
                  variant="contained"
                  onClick={() => handleBotPromptClick(qTitleStore.getState())}
                  style={{
                    backgroundColor: "#e0f7fa",
                    color: "#006064",
                    marginBottom: "10px",
                    width: "100%"
                  }}
                >
                  {qTitleStore.getState()}
                </Button>
        </div>
        </>}
        <div ref={messagesEndRef} />
      </List>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#e5fffc",
          borderRadius: "20px",
          padding: "8px",
          // marginBottom: "15px",
        }}
      >
       
        <TextField
          fullWidth
          variant="standard"
          placeholder="Type a message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          InputProps={{ disableUnderline: true }}
          style={{ marginLeft: "8px" }}
        />
        <Button
          color="primary"
          onClick={() => handleSendMessage()}
          style={{
            minWidth: "40px",
            height: "40px",
            borderRadius: "50%",
            color: "#449082",
          }}
        >
          <SendIcon />
        </Button>
        <input
          type="file"
          multiple
          accept=".pdf,.csv"
          onChange={handleFileUpload}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <Button
          color="secondary"
          onClick={() => fileInputRef.current.click()}
          style={{
            minWidth: "40px",
            height: "40px",
            borderRadius: "50%",
            marginLeft: "8px",
            color: "#295b52",
          }}
        >
          <AttachFileIcon />
        </Button>
      </div>
    </Paper>
  );
};

export default ChatBot;
