import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { answerStore, qTitleStore, solnStore } from "../redux/store";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getQByQID } from "../api";
import { useSearchParams } from "react-router-dom";

const CheckboxQuestion = ({ question, options, currentQuestion }) => {
  const [answers, setAnswers] = useState({});
  let [searchParamss] = useSearchParams();

  // useEffect(() => {
  //   async function  getData(){
  //     try {
  //       const res = await getQByQID(searchParamss.get("id"), localStorage.getItem("jwt"));
  //       setbackendAnswers(res.data);
  //     } catch (error) {
  //       console.error("Error fetching question data:", error);
  //     }
  //   }
  //   getData()
  // }, []);
  useEffect(() => {
    if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
      let parsedData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
      if(parsedData[currentQuestion]?.answers){
        setAnswers(parsedData[currentQuestion].answers)
      }else{
        // parsedData[currentQuestion].answers = {}
      }
    }
  }, [])

  // solnStore.subscribe(() => {
  //   let state = solnStore.getState();
  //   console.log("state",state)
  //   setAnswers({ ...state }); 
  //   answerStore.dispatch({
  //     type: "answer_object",
  //     payload: state,
  //   });
  // });

  // useEffect(() => {
  //   options.map((option) => {
  //     setAnswers((prevAnswers) => ({ ...prevAnswers, [option]: false }));
  //   });
  // }, []);

  const CustomCheckbox = (props) => (
    <Checkbox
      {...props}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<CheckBoxIcon sx={{ color: "#2B675C" }} />}
    />
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        paddingX: { xs: "2rem", sm: "2rem", md: "2rem", lg: "2rem" },
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        textAlign="center"
      >
        {question}
      </Typography>
      <FormControl component="fieldset" fullWidth>
        <FormGroup>
          {options.map((option) => (
            <Box
              key={option}
              sx={{
                backgroundColor: "#e5fffc",
                borderRadius: "0.5rem",
                padding: "1rem",
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={answers[option] ? answers[option] : false}
                    sx={{ color: "#232120" }}
                    onChange={(e) => {
                      const localData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
                      if(localData[currentQuestion].answers){
                        const ansFromLocal = localData[currentQuestion].answers
                        ansFromLocal[option] = e.target.checked
                        setAnswers({ ...answers, [option]: e.target.checked });
                        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(localData))
                        answerStore.dispatch({
                          type: "answer_object",
                          payload: { ...answers, [option]: e.target.checked },
                        });
                      }else{
                        localData[currentQuestion].answers = {}
                        localData[currentQuestion].answers[option] = e.target.checked 
                        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(localData))
                        answerStore.dispatch({
                          type: "answer_object",
                          payload: { ...answers, [option]: e.target.checked },
                        });
                      }
                    }}
                  />
                }
                label={option}
                sx={{ marginLeft: "0.5rem", flexGrow: 1, textAlign: "center" }}
              />
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CheckboxQuestion;
