import React, { useEffect, useState } from "react";
import { TextField, Typography, Box } from "@mui/material";
import { answerStore, qStore, qTitleStore, solnStore } from "../redux/store";
import { useSearchParams } from "react-router-dom";
import { getQByQID } from "../api";

const TextQuestion = ({ question,currentQuestion }) => {
  let [searchParamss] = useSearchParams();
  const [dataFromBackend, setdataFromBackend] = useState([])
  const [data, setData] = useState("");
  useEffect(() => {
      if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
        const parsedAns = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))[currentQuestion]
        if(parsedAns.answers){
          setData(parsedAns.answers)
          answerStore.dispatch({
            type: "answer_object",
            payload: parsedAns.answers,
          });
        }else{
          setData("")
        }
      }
    }
  , [currentQuestion,data])
  // useEffect(() => {
  //   const foundAnswer = dataFromBackend[currentQuestion]
  //   if(foundAnswer){
  //     setData(foundAnswer.answers)
  //     answerStore.dispatch({
  //       type: "answer_object",
  //       payload: foundAnswer.answers,
  //     });
  //   }else{
  //     setData("")
  //   }
  // }, [dataFromBackend])
  

  const handleChange = (e) => {
    e.preventDefault()
    if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
      const localData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
      if(localData[currentQuestion].answers){
        localData[currentQuestion].answers = e.target.value
        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(localData))
      }else{
        localData[currentQuestion].answers = e.target.value
        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(localData))
      }
    }
    setData(e.target.value);
    answerStore.dispatch({
      type: "answer_object",
      payload: e.target.value,
    });
  };

  solnStore.subscribe(() => {
    let soln = solnStore.getState();
    setData(soln);
    answerStore.dispatch({
      type: "answer_object",
      payload: soln,
    });
  });

  return (
    <Box
      sx={{
        marginBottom: "1.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: { xs: "2rem", sm: "2rem", md: "2rem" },
        paddingRight: { xs: "2rem", sm: "2rem", md: "2rem" },
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ color: "#989594", textAlign: "center" }}
      >
        {question}
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={data}
        onChange={handleChange}
        placeholder="Type your answer here"
        InputProps={{
          style: {
            padding: "0.5rem",
            borderRadius: "0.25rem",
            borderColor: "#FDFBFA",
            backgroundColor: "#e5fffc",
          },
          classes: {
            notchedOutline: "custom-notched-outline",
          },
        }}
        inputProps={{
          style: { color: "#4D4556" },
        }}
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#449082",
            },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#449082",
          },
        }}
      />
    </Box>
  );
};

export default TextQuestion;
