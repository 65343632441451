export const NAC = [
  "United States of America",
  "Canada",
  "Mexico",
  "Antigua and Barbuda",
  "Bahamas",
  "Barbados",
  "Cuba",
  "Dominica",
  "Dominican Rep.",
  "Grenada",
  "Haiti",
  "Jamaica",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Trinidad and Tobago",
  "Greenland",
];

export const WNS = [
  "Austria",
  "Belgium",
  "France",
  "Spain",
  "Portugal",
  "Denmark",
  "Estonia",
  "Iceland",
  "Jersey",
  "Latvia",
  "Lithuania",
  "Albania",
  "Andorra",
  "Bosnia and Herzegovina",
  "Croatia",
  "Gibraltar",
  "Greece",
  "Serbia",
  "Italy",
  "Germany",
  "Ireland",
  "Sweden",
  "Finland",
  "Norway",
  "Liechtenstein",
  "Luxembourg",
  "Monaco",
  "Netherlands",
  "Switzerland",
  "United Kingdom",
];

export const Oceania = [
  "Australia",
  "New Zealand",

  "Cambodia",

  "Laos",

  "Fiji",
  "Papua New Guinea",
  "Solomon Islands",
  "Vanuata",
  "Federated States of Micronesia",
  "Kiribati",
  "Marshall Islands",
  "Nauru",
  "Palau",
  "Samoa",
  "Tonga",
  "Tuvalu",
  "French Polynesia",
  "New Caledonia",
  "Wallis and Futuna",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Cook Islands",
  "Niue",
  "Tokelau",
  "Pitcairn Islands",
];

export const SSA = [
  "Benin",
  "Burkina Faso",
  "Botswana",
  "Burundi",
  "Cape Verde",
  "Comoros",
  "Côte d'Ivoire",
  "Dem. Rep. Congo",
  "Djibouti",
  "Eswatini",
  "Ethiopia",
  "Gabon",
  "Equatorial Guinea",
  "Eritrea",
  "Kenya",
  "Lesotho",
  "liberia",
  "Madagascar",
  "Malawi",
  "Mozambique",
  "Namibia",
  "Rwanda",
  "São Tomé and Príncipe",
  "Seychelles",
  "Somalia",
  "South Africa",
  "S. Sudan",
  "Tanzania",
  "Uganda",
  "Zambia",
  "Zimbabwe",
  "Gambia",
  "Ghana",
  "Guinea",
  "Guinea-Bissau",
  "Mali",
  "Mauritania",
  "Niger",
  "Nigeria",
  "Senegal",
  "Sierra Leone",
  "Togo",
  "Angola",
  "Cameroon",
  "Central African Rep.",
  "Chad",
  "Congo",
];

export const LAC = [
  "Belize",
  "Costa Rica",
  "El Salvador",
  "Guatemala",
  "Honduras",
  "Nicaragua",
  "Panama",
  "Argentina",
  "Bolivia",
  "Brazil",
  "Chile",
  "Colombia",
  "Ecuador",
  "Guyana",
  "Paraguay",
  "Peru",
  "Suriname",
  "Uruguay",
  "Venezuela",
];

export const SA = [
  "Afghanistan",
  "Bhutan",
  "Bangladesh",
  "India",
  "Maldives",
  "Nepal",
  "Pakistan",
  "Sri Lanka",
  "Iran",
];

export const ESE = [
  "China",
  "Japan",
  "Mongolia",
  "North Korea",
  "South Korea",
  "Indonesia",
  "Malaysia",
  "Myanmar",
  "Philippines",
  "Singapore",
  "Thailand",
  "Vietnam",
];

export const MENA = [
  "Algeria",
  "Egypt",
  "Libya",
  "Morocco",
  "W. Sahara",
  "Sudan",
  "Tunisia",
  "Armenia",
  "Azerbaijan",
  "Bahrain",
  "Cyprus",
  "Georgia",
  "Iraq",
  "Israel",
  "Jordan",
  "Kuwait",
  "Lebanon",
  "Oman",
  "Qatar",
  "Saudi Arabia",
  "Palestine",
  "Syria",
  "Turkey",
  "United Arab Emirates",
  "Yemen",
];

export const EEC = [
  "Belarus",
  "Russia",
  "Kazakhstan",
  "Kyrgyzstan",
  "Tajikistan",
  "Turkmenistan",
  "Uzbekistan",
  "Ukraine",
  "Moldova",
  "Bulgaria",
  "Czechia",
  "Hungary",
  "Poland",
  "Romania",
  "Slovakia",
];
