import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Box,
} from "@mui/material";
import { answerStore, qStore, qTitleStore, solnStore } from "../redux/store";
import { getQByQID } from "../api";
import { useSearchParams } from "react-router-dom";

const MultipleChoiceQuestion = ({ question, options, run, currentQuestion }) => {

  let [searchParamss] = useSearchParams();
  const [answer, setAnswer] = useState("");
  // const [dataFromBackend, setdataFromBackend] = useState([])
  // useEffect(() => {
  //   async function check() {
  //     try {
  //       const res = await getQByQID(searchParamss.get("id"), localStorage.getItem("jwt"));
  //       setdataFromBackend(res.data);
  //     } catch (error) {
  //       console.error("Error fetching question data:", error);
  //     }
  //   }
  //   check();
  // }, [run.reRun]);
  // useEffect(() => {
  //   if (dataFromBackend.length > 0) {
  //     const foundAnswer = dataFromBackend[currentQuestion]
  //     if (foundAnswer) {
  //       setAnswer(foundAnswer.answers);
  //       answerStore.dispatch({
  //         type: "answer_object",
  //         payload: foundAnswer.answers,
  //       });
  //     } else {
  //       setAnswer("");
  //     }
  //   }
  // }, [dataFromBackend, qTitleStore.getState()]);
  useEffect(() => {
    if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
      let parsedData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
      if(parsedData[currentQuestion]?.answers){
        setAnswer(parsedData[currentQuestion].answers)
      }else{
        setAnswer("")
      }
    }
  }, [currentQuestion])
  

  const handleChange = (e) => {
    setAnswer(e.target.value);
    if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
      let parsedData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
      if(parsedData[currentQuestion]?.answers){
        parsedData[currentQuestion].answers = e.target.value
        localStorage.removeItem(`questionnareData-${searchParamss.get("id")}`)
        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(parsedData))
      }else{
        parsedData[currentQuestion].answers = e.target.value
        localStorage.removeItem(`questionnareData-${searchParamss.get("id")}`)
        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`, JSON.stringify(parsedData))
      }
    }
    answerStore.dispatch({
      type: "answer_object",
      payload: e.target.value,
    });
  };

  solnStore.subscribe(() => {
    let state = solnStore.getState();
    // setAnswer(state);
    // console.log("state",state)
    // answerStore.dispatch({
    //   type: "answer_object",
    //   payload: state,
    // });
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        marginBottom: "1.5rem",
        paddingX: { xs: "2rem", sm: "2rem", md: "2rem", lg: "2rem" },
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {question}
      </Typography>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup>
          {options.map((option) => (
            <Box
              key={option}
              sx={{
                backgroundColor: "#B1FFE8",
                borderRadius: "0.5rem",
                padding: "1rem",
                fontFamily: "Montserrat",
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FormControlLabel
                value={option}
                checked={answer === option}
                onChange={(e) => {
                  handleChange(e);
                }}
                control={
                  <Radio
                    sx={{
                      color: "#34D4B7",
                      "&.Mui-checked": {
                        color: "#2B675C",
                      },
                    }}
                  />
                }
                label={option}
                sx={{ marginLeft: "0.5rem", flexGrow: 1 }}
              />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default MultipleChoiceQuestion;
