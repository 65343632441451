import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Chip,
  Box,
  ListSubheader,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import * as CountryData from "./countries";
import { answerStore, DeletedPlaceStore, qTitleStore } from "../redux/store";
import { useSearchParams } from "react-router-dom";

const formatCountryData = () => {
  return Object.entries(CountryData).map(([region, countries]) => ({
    region,
    countries: countries.map((country) => ({ name: country, region })),
  }));
};

const CountryDropdown = ({ setSelectionMode, setSelectedPlaces, currentQuestion }) => {
  const [selectionType, setSelectionType] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function getVALS(data) {
    let selectedCols
    if(data){
       selectedCols = Object.keys(data);
    }else{
      return  { 
        columnsSelected : [], 
        selectedOption : {}, 
        mode : '' 
      }
    }
    const transformedData = {};
  
    const regions = [
      "NAC", "WNS", "Oceania", "MENA", "EEC", "ESE", "LAC", "SA", "SSA"
    ];
    let mode = "countries"; 
    for (const country in data) {
      if (typeof data[country] === 'object') {
        transformedData[country] = Object.values(data[country])[0]; 
      } else {
        transformedData[country] = data[country]; 
      }
    }
  const hasRegion = selectedCols.some(col => regions.includes(col));
  
    if (hasRegion) {
      mode = "regions";
    }
    
    return { columnsSelected :  selectedCols, selectedOption : transformedData, mode };
  }

  useEffect(() => {
    if(localStorage.getItem(`questionnareData-${searchParams.get("id")}`)){
      const parsed = JSON.parse(localStorage.getItem(`questionnareData-${searchParams.get("id")}`))[currentQuestion]
      const dataFromFunction = getVALS(parsed?.answers)
      setSelectionType(dataFromFunction.mode)
      setSelectedItems(dataFromFunction.columnsSelected)
    }
  }, [qTitleStore.getState()])
  
  const handleTypeChange = (event) => {
    // answerStore.dispatch({ type: "RESET_ANSWER", payload: {} });
    setSelectionType(event.target.value);
    setSelectionMode(event.target.value);
    const state = answerStore.getState();
    for (let key in state) {
      DeletedPlaceStore.dispatch({ type: "DELETE_PLACE", payload: key });
    }
    localStorage.setItem(
      qTitleStore.getState(),
      JSON.stringify({
        columnsSelected: [],
        selectedOption: {},
        mode: event.target.value,
      })
    );
    setSelectedItems([]);
    setOpen(true);
  };

  const handleItemSelect = (event) => {
    const value = event.target.value;

    if (selectionType === "region") {
      setSelectedRegions((prevRegions) => {
        const updatedRegions = [...prevRegions, ...value]; // Spread the array of values if multiple
        // setSelectedPlaces(updatedRegions);
        return updatedRegions;
      });
    } else if (selectionType === "countries") {
      setSelectedCountries((prevCountries) => {
        const updatedCountries = [...prevCountries, ...value]; // Spread the array of values if multiple
        // setSelectedPlaces(updatedCountries);
        return updatedCountries;
      });
    }

    setSelectedItems(value); // Ensure this is treated as an array
  };

  useEffect(() => {
    const state = answerStore.getState();
    for (let key in state) {
      DeletedPlaceStore.dispatch({ type: "DELETE_PLACE", payload: key });
    }
  
    if (selectionType === "region" || selectionType === "countries") {
      console.log("dont run")
    }else if(selectionType == ""){
      answerStore.dispatch({ type: "RESET_ANSWER", payload: {} });
    }
  }, [selectionType]);
  

  useEffect(() => {
    setSelectedPlaces(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    const unsubscribe = qTitleStore.subscribe(() => {
      setSelectedItems([]);
    });
  }, []);

  const handleBackToSelection = () => {
    // answerStore.dispatch({ type: "RESET_ANSWER", payload: {} });
    setSelectionType("");
    setSelectedItems([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const getValue = (region) => {
    const regionMap = {
      NAC: "North America and the Caribbean",
      WNS: "Western North and South",
      EEC: "Eastern European Countries",
      ESE: "Eastern and Southern Europe",
      LAC: "Latin America and the Caribbean",
      MENA: "Middle East and North Africa",
      SA: "South Asia",
      SSA: "Sub-Saharan Africa",
      OCE: "Oceania",
    };
    return regionMap[region] || "Oceania";
  };
  const renderDropdown = () => {
    if (selectionType === "") {
      return (
        <Select
          value={selectionType}
          onChange={handleTypeChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          displayEmpty
          sx={{ width: "100%", marginBottom: 2 }}
        >
          <MenuItem value="" disabled>
            Choose selection type
          </MenuItem>
          <MenuItem value="countries">Countries</MenuItem>
          <MenuItem value="regions">Regions</MenuItem>
        </Select>
      );
    }
    const formattedData = formatCountryData();
    const cont = formatCountryData().flatMap(({ countries }) => countries);
    cont.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
    const items = selectionType === "countries" ? cont : formatCountryData();
    return (
      <Select
        multiple
        value={selectedItems}
        onChange={handleItemSelect}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em>
                {selectionType === "countries"
                  ? "Select countries"
                  : "Select regions"}
              </em>
            );
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          );
        }}
        sx={{ width: "100%", marginBottom: 2 }}
      >
        <ListSubheader>
          <MenuItem
            onClick={handleBackToSelection}
            sx={{ color: "primary.main" }}
          >
            <ArrowBackIcon sx={{ mr: 1 }} /> Back to selection type
          </MenuItem>
        </ListSubheader>
        {selectionType === "regions"
          ? items.map(({ region, countries }) => (
              <MenuItem key={region} value={region}>
                <Box display="flex" alignItems="center" width="100%">
                  <span>{getValue(region)}</span>
                  <Box flexGrow={1} />
                  <Tooltip
                    title={countries.map((c) => c.name).join(", ")}
                    arrow
                  >
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </MenuItem>
            ))
          : items.map(({ name, region }) => (
              <MenuItem key={name} value={name}>
                <Box display="flex" alignItems="center" width="100%">
                  <span>{name}</span>
                  <Box flexGrow={1} />
                  <Tooltip title={`Region: ${region}`} arrow>
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </MenuItem>
            ))}
      </Select>
    );
  };

  return renderDropdown();
};

export default CountryDropdown;