export let countriesArr = [
    "Zimbabwe",
    "Zambia",
    "Yemen",
    "Vietnam",
    "Venezuela",
    "Vatican",
    "Vanuatu",
    "Uzbekistan",
    "Uruguay",
    "Micronesia",
    "Marshall Is.",
    "N. Mariana Is.",
    "U.S. Virgin Is.",
    "Guam",
    "American Samoa",
    "Puerto Rico",
    "United States of America",
    "S. Geo. and the Is.",
    "Br. Indian Ocean Ter.",
    "Saint Helena",
    "Pitcairn Is.",
    "Anguilla",
    "Falkland Is.",
    "Cayman Is.",
    "Bermuda",
    "British Virgin Is.",
    "Turks and Caicos Is.",
    "Montserrat",
    "Jersey",
    "Guernsey",
    "Isle of Man",
    "United Kingdom",
    "United Arab Emirates",
    "Ukraine",
    "Uganda",
    "Turkmenistan",
    "Turkey",
    "Tunisia",
    "Trinidad and Tobago",
    "Tonga",
    "Togo",
    "Timor-Leste",
    "Thailand",
    "Tanzania",
    "Tajikistan",
    "Taiwan",
    "Syria",
    "Switzerland",
    "Sweden",
    "eSwatini",
    "Suriname",
    "S. Sudan",
    "Sudan",
    "Sri Lanka",
    "Spain",
    "South Korea",
    "South Africa",
    "Somalia",
    "Somaliland",
    "Solomon Is.",
    "Slovakia",
    "Slovenia",
    "Singapore",
    "Sierra Leone",
    "Seychelles",
    "Serbia",
    "Senegal",
    "Saudi Arabia",
    "São Tomé and Principe",
    "San Marino",
    "Samoa",
    "St. Vin. and Gren.",
    "Saint Lucia",
    "St. Kitts and Nevis",
    "Rwanda",
    "Russia",
    "Romania",
    "Qatar",
    "Portugal",
    "Poland",
    "Philippines",
    "Peru",
    "Paraguay",
    "Papua New Guinea",
    "Panama",
    "Palau",
    "Pakistan",
    "Oman",
    "Norway",
    "North Korea",
    "Nigeria",
    "Niger",
    "Nicaragua",
    "New Zealand",
    "Niue",
    "Cook Is.",
    "Netherlands",
    "Aruba",
    "Curaçao",
    "Nepal",
    "Nauru",
    "Namibia",
    "Mozambique",
    "Morocco",
    "W. Sahara",
    "Montenegro",
    "Mongolia",
    "Moldova",
    "Monaco",
    "Mexico",
    "Mauritius",
    "Mauritania",
    "Malta",
    "Mali",
    "Maldives",
    "Malaysia",
    "Malawi",
    "Madagascar",
    "Macedonia",
    "Luxembourg",
    "Lithuania",
    "Liechtenstein",
    "Libya",
    "Liberia",
    "Lesotho",
    "Lebanon",
    "Latvia",
    "Laos",
    "Kyrgyzstan",
    "Kuwait",
    "Kosovo",
    "Kiribati",
    "Kenya",
    "Kazakhstan",
    "Jordan",
    "Japan",
    "Jamaica",
    "Italy",
    "Israel",
    "Palestine",
    "Ireland",
    "Iraq",
    "Iran",
    "Indonesia",
    "India",
    "Iceland",
    "Hungary",
    "Honduras",
    "Haiti",
    "Guyana",
    "Guinea-Bissau",
    "Guinea",
    "Guatemala",
    "Grenada",
    "Greece",
    "Ghana",
    "Germany",
    "Georgia",
    "Gambia",
    "Gabon",
    "France",
    "St. Pierre and Miquelon",
    "Wallis and Futuna Is.",
    "St-Martin",
    "St-Barthélemy",
    "Fr. Polynesia",
    "New Caledonia",
    "Fr. S. Antarctic Lands",
    "Åland",
    "Finland",
    "Fiji",
    "Ethiopia",
    "Estonia",
    "Eritrea",
    "Eq. Guinea",
    "El Salvador",
    "Egypt",
    "Ecuador",
    "Dominican Rep.",
    "Dominica",
    "Djibouti",
    "Greenland",
    "Faeroe Is.",
    "Denmark",
    "Czechia",
    "N. Cyprus",
    "Cyprus",
    "Cuba",
    "Croatia",
    "Côte d'Ivoire",
    "Costa Rica",
    "Dem. Rep. Congo",
    "Congo",
    "Comoros",
    "Colombia",
    "China",
    "Macao",
    "Hong Kong",
    "Chile",
    "Chad",
    "Central African Rep.",
    "Cabo Verde",
    "Canada",
    "Cameroon",
    "Cambodia",
    "Myanmar",
    "Burundi",
    "Burkina Faso",
    "Bulgaria",
    "Brunei",
    "Brazil",
    "Botswana",
    "Bosnia and Herz.",
    "Bolivia",
    "Bhutan",
    "Benin",
    "Belize",
    "Belgium",
    "Belarus",
    "Barbados",
    "Bangladesh",
    "Bahrain",
    "Bahamas",
    "Azerbaijan",
    "Austria",
    "Australia",
    "Indian Ocean Ter.",
    "Heard I. and McDonald Is.",
    "Norfolk Island",
    "Ashmore and Cartier Is.",
    "Armenia",
    "Argentina",
    "Antigua and Barb.",
    "Angola",
    "Andorra",
    "Algeria",
    "Albania",
    "Afghanistan",
    "Siachen Glacier",
    "Antarctica",
    "Sint Maarten"
  ]
export const findRegion = (country) => {
    const regions = {
      NAC :  [
        "United States of America",
        "Canada",
        "Mexico",
        "Antigua and Barbuda",
        "Bahamas",
        "Barbados",
        "Cuba",
        "Dominica",
        "Dominican Rep.",
        "Grenada",
        "Haiti",
        "Jamaica",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Trinidad and Tobago",
        "Greenland",
      ],
      WNS: [
        "Austria",
        "Belgium",
        "France",
        "Spain",
        "Portugal",
        "Denmark",
        "Estonia",
        "Iceland",
        "Jersey",
        "Latvia",
        "Lithuania",
        "Albania",
        "Andorra",
        "Bosnia and Herzegovina",
        "Croatia",
        "Gibraltar",
        "Greece",
        "Serbia",
        "Italy",
        "Germany",
        "Ireland",
        "Sweden",
        "Finland",
        "Norway",
        "Liechtenstein",
        "Luxembourg",
        "Monaco",
        "Netherlands",
        "Switzerland",
        "United Kingdom",
      ],
      Oceania : [
        "Australia",
        "New Zealand",
      
        "Cambodia",
      
        "Laos",
      
        "Fiji",
        "Papua New Guinea",
        "Solomon Islands",
        "Vanuata",
        "Federated States of Micronesia",
        "Kiribati",
        "Marshall Islands",
        "Nauru",
        "Palau",
        "Samoa",
        "Tonga",
        "Tuvalu",
        "French Polynesia",
        "New Caledonia",
        "Wallis and Futuna",
        "American Samoa",
        "Guam",
        "Northern Mariana Islands",
        "Cook Islands",
        "Niue",
        "Tokelau",
        "Pitcairn Islands",
      ],
      SSA : [
        "Benin",
        "Burkina Faso",
        "Botswana",
        "Burundi",
        "Cape Verde",
        "Comoros",
        "Côte d'Ivoire",
        "Dem. Rep. Congo",
        "Djibouti",
        "Eswatini",
        "Ethiopia",
        "Gabon",
        "Equatorial Guinea",
        "Eritrea",
        "Kenya",
        "Lesotho",
        "liberia",
        "Madagascar",
        "Malawi",
        "Mozambique",
        "Namibia",
        "Rwanda",
        "São Tomé and Príncipe",
        "Seychelles",
        "Somalia",
        "South Africa",
        "S. Sudan",
        "Tanzania",
        "Uganda",
        "Zambia",
        "Zimbabwe",
        "Gambia",
        "Ghana",
        "Guinea",
        "Guinea-Bissau",
        "Mali",
        "Mauritania",
        "Niger",
        "Nigeria",
        "Senegal",
        "Sierra Leone",
        "Togo",
        "Angola",
        "Cameroon",
        "Central African Rep.",
        "Chad",
        "Congo",
      ],
      LAC: [
        "Belize",
        "Costa Rica",
        "El Salvador",
        "Guatemala",
        "Honduras",
        "Nicaragua",
        "Panama",
        "Argentina",
        "Bolivia",
        "Brazil",
        "Chile",
        "Colombia",
        "Ecuador",
        "Guyana",
        "Paraguay",
        "Peru",
        "Suriname",
        "Uruguay",
        "Venezuela",
      ],
      SA:[
        "Afghanistan",
        "Bhutan",
        "Bangladesh",
        "India",
        "Maldives",
        "Nepal",
        "Pakistan",
        "Sri Lanka",
        "Iran",
      ],
      MENA:[
        "Algeria",
        "Egypt",
        "Libya",
        "Morocco",
        "W. Sahara",
        "Sudan",
        "Tunisia",
        "Armenia",
        "Azerbaijan",
        "Bahrain",
        "Cyprus",
        "Georgia",
        "Iraq",
        "Israel",
        "Jordan",
        "Kuwait",
        "Lebanon",
        "Oman",
        "Qatar",
        "Saudi Arabia",
        "Palestine",
        "Syria",
        "Turkey",
        "United Arab Emirates",
        "Yemen",
      ],
      EEC:  [
        "Belarus",
        "Russia",
        "Kazakhstan",
        "Kyrgyzstan",
        "Tajikistan",
        "Turkmenistan",
        "Uzbekistan",
        "Ukraine",
        "Moldova",
        "Bulgaria",
        "Czechia",
        "Hungary",
        "Poland",
        "Romania",
        "Slovakia",
      ]
    };
  
    for (const [region, countries] of Object.entries(regions)) {
      if (countries.includes(country)) {
        return region;
      }
    }
  
    return "Region not found";
  };