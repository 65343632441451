import React from "react";
import { useState, useEffect } from "react";
import { Slider, Typography, Box } from "@mui/material";
import { qTitleStore, answerStore, solnStore } from "../redux/store";
import { useSearchParams } from "react-router-dom";

const ScaleQuestion = ({ minLabel, maxLabel, currentQuestion }) => {
  // Retrieve the question title from qTitleStore
  let [searchParamss] = useSearchParams();
  const question = qTitleStore.getState();
  
  // Local storage key based on the question title
  const localStorageKey = `scale-value-${question.replace(/[^a-zA-Z0-9]/g, '_')}`;
  
 
  
  const [value, setValue] = useState(3);
  
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
    if(localData[currentQuestion].answers){
      setValue(localData[currentQuestion].answers)
    }else{
      setValue(3)
    }
  }, [currentQuestion])
  
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    // localStorage.setItem(localStorageKey, newValue.toString());
    if(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`)){
      const localData = JSON.parse(localStorage.getItem(`questionnareData-${searchParamss.get("id")}`))
      if(localData){
        localData[currentQuestion].answers = newValue
        localStorage.setItem(`questionnareData-${searchParamss.get("id")}`,JSON.stringify(localData) )
      }
    }
    answerStore.dispatch({
      type: "answer_object",
      payload:  newValue 
    });
  };
  

  // Handle changes in qTitleStore
  // useEffect(() => {
  //   const unsubscribe = qTitleStore.subscribe(() => {
  //     const newQuestion = qTitleStore.getState();
  //     const newLocalStorageKey = `scale-value-${newQuestion.replace(/[^a-zA-Z0-9]/g, '_')}`;
  //     const newValue = localStorage.getItem(newLocalStorageKey) !== null ?
  //                     localStorage.getItem(newLocalStorageKey) : 3;
  //     setValue(newValue);
  //   });
  //   return () => unsubscribe();
  // }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        marginBottom: "1.5rem",
        paddingX: { xs: "2rem", sm: "2rem", md: "2rem", lg: "2rem" },
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ color: "#a4a1a0", textAlign: "center", fontFamily: "DM Sans, sans-serif" }}
      >
        {question}
      </Typography>
      <Box display="flex" alignItems="center" mt="0.5rem" width="80%">
        <Slider
          value={value}
          onChange={handleSliderChange}
          min={1}
          max={5}
          step={0.1} 
          marks={[
            { value: 1, label: minLabel },
            { value: 5, label: maxLabel },
          ]}
          valueLabelDisplay="auto"
          sx={{
            flexGrow: 1,
            color: "#34D4B7",
            "& .MuiSlider-thumb": {
              backgroundColor: "#1EC8DF",
            },
            "& .MuiSlider-valueLabel": {
              color: "#1EC8DF",
            },
            "& .MuiSlider-markLabel": {
              color: "#4D4556",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ScaleQuestion;