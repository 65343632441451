import React, { useRef, useEffect, useState, useCallback } from "react";
import { Chart, registerables } from "chart.js";
import {
  ChoroplethController,
  GeoFeature,
  ProjectionScale,
  ColorScale,
} from "chartjs-chart-geo";
import { feature } from "topojson-client";
import "chartjs-chart-geo";
import { EEC, ESE, LAC, MENA, NAC, Oceania, SA, SSA, WNS } from "./countries";
import {
  answerStore,
  DeletedPlaceStore,
  gridStore,
  mapStore,
  qTitleStore,
} from "../redux/store";

Chart.register(
  ...registerables,
  ChoroplethController,
  GeoFeature,
  ProjectionScale,
  ColorScale
);

function ChoroplethMap() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [map, setMap] = useState(false);
  const [question, setQuestion] = useState();
  const [initialState,setInitailState] = useState({})

  useEffect(() => {
    const unsubscribe = qTitleStore.subscribe(() => {
      setQuestion(qTitleStore.getState());
    });
    return () => unsubscribe();
  }, [qTitleStore]); // Empty dependency array if this should only run once
  useEffect(() => {
    const unsubscribe = answerStore.subscribe(() => {
      setInitailState(answerStore.getState())  
    });

  }, [qTitleStore]); 

  
  // initialState
  
  // const [chart, setChart] = useState(null);

  function getIntensity(country) {
    return Object.values(country)[0];
  }
  // useEffect(() => {
  //   console.log("Updated state from store:", answerStore.getState());
  //   updateChartData(answerStore.getState());
  // }, [answerStore.getState()]);
  
  useEffect(() => {
    let chart;
    
    const createChart = () => {
      const ctx = chartRef.current.getContext("2d");
      console.log(question);
      fetch("https://unpkg.com/world-atlas/countries-50m.json")
        .then((response) => response.json())
        .then((data) => {
          const countries = feature(data, data.objects.countries).features;

          chart = new Chart(ctx, {
            type: "choropleth",
            data: {
              labels: countries.map((d) => d.properties.name),
              datasets: [
                {
                  label: "Countries",
                  data: countries.map((d) => ({
                    feature: d,
                    value: 0,
                  })),
                },
              ],
            },
            options: {
              showOutline: true,
              showGraticule: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                projection: {
                  axis: "x",
                  projection: "equalEarth",
                },
              },
              color: {
                type: "color",
                quantize: 5,
                legend: {
                  position: "bottom-right",
                  align: "bottom",
                },
              },
            },
          });

          updateChartData();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const deleteChartData = () => {
      const state = DeletedPlaceStore.getState();
      if (chart) {
        if (state && state !== "") {
          let index = chart.data.labels.indexOf(state);
          if (index !== -1) {
            chart.data.datasets[0].data[index].value = 0;
            chart.update();
          } else {
            if (state === "NAC") {
              for (let i = 0; i < NAC.length; i++) {
                let index = chart.data.labels.indexOf(NAC[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "WNS") {
              for (let i = 0; i < WNS.length; i++) {
                let index = chart.data.labels.indexOf(WNS[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "Oceania") {
              for (let i = 0; i < Oceania.length; i++) {
                let index = chart.data.labels.indexOf(Oceania[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "SSA") {
              for (let i = 0; i < SSA.length; i++) {
                let index = chart.data.labels.indexOf(SSA[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "LAC") {
              for (let i = 0; i < LAC.length; i++) {
                let index = chart.data.labels.indexOf(LAC[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "SA") {
              for (let i = 0; i < SA.length; i++) {
                let index = chart.data.labels.indexOf(SA[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "ESE") {
              for (let i = 0; i < ESE.length; i++) {
                let index = chart.data.labels.indexOf(ESE[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "MENA") {
              for (let i = 0; i < MENA.length; i++) {
                let index = chart.data.labels.indexOf(MENA[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "EEC") {
              for (let i = 0; i < EEC.length; i++) {
                let index = chart.data.labels.indexOf(EEC[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            } else if (state === "SSA") {
              for (let i = 0; i < SSA.length; i++) {
                let index = chart.data.labels.indexOf(SSA[i]);
                if (index !== -1) {
                  chart.data.datasets[0].data[index].value = 0;
                }
              }
              chart.update();
            }
          }
        }
      }
    };

    const updateChartData = () => {
      
      if (chart) {
        console.log("initialState", initialState)
        let state = answerStore.getState() ;

        
        if (!state || Object.keys(state).length === 0) {
          state = initialState; 
        }
        if(Object.keys(state).length === 0){
          state = initialState
        }
        for (let obj in state) {
          if (obj === "NAC") {
            for (let i = 0; i < NAC.length; i++) {
              let index = chart.data.labels.indexOf(NAC[i]);
              let option = state["NAC"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "WNS") {
            for (let i = 0; i < WNS.length; i++) {
              let index = chart.data.labels.indexOf(WNS[i]);
              let option = state["WNS"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "Oceania") {
            for (let i = 0; i < Oceania.length; i++) {
              let index = chart.data.labels.indexOf(Oceania[i]);
              let option = state["Oceania"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "SSA") {
            for (let i = 0; i < SSA.length; i++) {
              let index = chart.data.labels.indexOf(SSA[i]);
              let option = state["SSA"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "LAC") {
            for (let i = 0; i < LAC.length; i++) {
              let index = chart.data.labels.indexOf(LAC[i]);
              let option = state["LAC"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "SA") {
            for (let i = 0; i < SA.length; i++) {
              let index = chart.data.labels.indexOf(SA[i]);
              let option = state["SA"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "ESE") {
            for (let i = 0; i < ESE.length; i++) {
              let index = chart.data.labels.indexOf(ESE[i]);
              let option = state["ESE"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "MENA") {
            for (let i = 0; i < MENA.length; i++) {
              let index = chart.data.labels.indexOf(MENA[i]);
              let option = state["MENA"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else if (obj === "EEC") {
            for (let i = 0; i < EEC.length; i++) {
              let index = chart.data.labels.indexOf(EEC[i]);
              let option = state["EEC"];
              if (index !== -1) {
                if (option == "Insignificant") {
                  chart.data.datasets[0].data[index].value = 5;
                } else if (option == "Marginal") {
                  chart.data.datasets[0].data[index].value = 10;
                } else if (option == "Significant") {
                  chart.data.datasets[0].data[index].value = 15;
                } else if (option == "Dominant") {
                  chart.data.datasets[0].data[index].value = 20;
                } else {
                  chart.data.datasets[0].data[index].value = 10;
                }
              }
            }
            chart.update();
          } else {
            let index = chart.data.labels.indexOf(obj);
            let option = getIntensity(state[obj]);
            if (index !== -1) {
              if (option == "Insignificant") {
                chart.data.datasets[0].data[index].value = 5;
              } else if (option == "Marginal") {
                chart.data.datasets[0].data[index].value = 10;
              } else if (option == "Significant") {
                chart.data.datasets[0].data[index].value = 15;
              } else if (option == "Dominant") {
                chart.data.datasets[0].data[index].value = 20;
              } else {
                chart.data.datasets[0].data[index].value = 10;
              }
            }
            chart.update();
          }
          chart.update();
        }
        chart.update();
      }
    };

    createChart();

    const unsubscribe = answerStore.subscribe(updateChartData);
    const unsubscribeDeletedPlace =
      DeletedPlaceStore.subscribe(deleteChartData);
      
    return () => {
      unsubscribe();
      unsubscribeDeletedPlace();
      if (chart) {
        chart.destroy();
      }
    };
  }, [question]);

  return <canvas ref={chartRef} />;
}

export default React.memo(ChoroplethMap);

// import React, { useRef, useEffect, useState } from "react";
// import { Chart, registerables } from "chart.js";
// import { ChoroplethController, GeoFeature } from "chartjs-chart-geo";
// import { feature } from "topojson-client";
// import "chartjs-chart-geo";
// import { EEC, ESE, LAC, MENA, NAC, Oceania, SA, SSA, WNS } from "./countries";
// import { answerStore, gridStore } from "../redux/store";

// Chart.register(...registerables, ChoroplethController, GeoFeature);

// function ChoroplethMap() {
//   const chartRef = useRef(null);
//   const [map, setMap] = useState(false);

//   // const [chart, setChart] = useState(null);

//   useEffect(() => {
//     const ctx = chartRef.current.getContext("2d");

//     fetch("https://unpkg.com/world-atlas/countries-50m.json")
//       .then((response) => response.json())
//       .then((data) => {
//         const countries = feature(data, data.objects.countries).features;

//         const chart = new Chart(ctx, {
//           type: "choropleth",
//           data: {
//             labels: countries.map((d) => d.properties.name),
//             datasets: [
//               {
//                 label: "Countries",
//                 data: countries.map((d) => ({
//                   feature: d,
//                   value: 0,
//                 })),
//               },
//             ],
//           },
//           options: {
//             showOutline: true,
//             showGraticule: true,
//             plugins: {
//               legend: {
//                 display: false,
//               },
//             },
//             scales: {
//               projection: {
//                 axis: "x",
//                 projection: "equalEarth",
//               },
//             },
//           },
//         });

//         const unsubscribe = answerStore.subscribe(() => {
//           const state = answerStore.getState();
//           if (chart) {
//             for (let obj in state) {
//               if (obj === "NAC") {
//                 for (let i = 0; i < NAC.length; i++) {
//                   let index = chart.data.labels.indexOf(NAC[i]);
//                   let option = state["NAC"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "WNS") {
//                 for (let i = 0; i < WNS.length; i++) {
//                   let index = chart.data.labels.indexOf(WNS[i]);
//                   let option = state["WNS"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "Oceania") {
//                 for (let i = 0; i < Oceania.length; i++) {
//                   let index = chart.data.labels.indexOf(Oceania[i]);
//                   let option = state["Oceania"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "SSA") {
//                 for (let i = 0; i < SSA.length; i++) {
//                   let index = chart.data.labels.indexOf(SSA[i]);
//                   let option = state["SSA"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "LAC") {
//                 for (let i = 0; i < LAC.length; i++) {
//                   let index = chart.data.labels.indexOf(LAC[i]);
//                   let option = state["LAC"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "SA") {
//                 for (let i = 0; i < SA.length; i++) {
//                   let index = chart.data.labels.indexOf(SA[i]);
//                   let option = state["SA"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "ESE") {
//                 for (let i = 0; i < ESE.length; i++) {
//                   let index = chart.data.labels.indexOf(ESE[i]);
//                   let option = state["ESE"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "MENA") {
//                 for (let i = 0; i < MENA.length; i++) {
//                   let index = chart.data.labels.indexOf(MENA[i]);
//                   let option = state["MENA"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               } else if (obj === "EEC") {
//                 for (let i = 0; i < EEC.length; i++) {
//                   let index = chart.data.labels.indexOf(EEC[i]);
//                   let option = state["EEC"];
//                   if (index !== -1) {
//                     if (option == "Insignificant") {
//                       chart.data.datasets[0].data[index].value = 5;
//                     } else if (option == "Marginal") {
//                       chart.data.datasets[0].data[index].value = 10;
//                     } else if (option == "Significant") {
//                       chart.data.datasets[0].data[index].value = 15;
//                     } else if (option == "Dominant") {
//                       chart.data.datasets[0].data[index].value = 20;
//                     }
//                   }
//                 }
//                 chart.update();
//               }
//             }
//           }
//         });

//         //  setChart(chart);
//         return () => {
//           chart.destroy();
//         };
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   return <canvas ref={chartRef} />;
// }

// export default ChoroplethMap;
