import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ProgressCircles from "./ProgressCircles";
import RadarChart from "./radarChart";
import VerticalBarChart from "./VerticalBar";
import "./styles.css";
import { scoreStore } from "../redux/store";
import NewBubbleComponent from "./NewBubbleComponent";
const CommonComponent = ({ handleNext, section }) => {
  // useEffect(() => {
  //   if (chartRef.current) {
  //     // Access the internal Chart.js instance
  //     const chartInstance = chartRef.current;
  //     chartInstance.options.plugins.customPlugin = customPlugin;
  //     chartInstance.update();
  //   }
  // }, [scoreData]); // Update when scoreData changes

  const [dataset, setDataset] = useState(0);
  useEffect(() => {
    const dataSet = scoreStore.getState();
    setDataset(dataSet);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      width="100vw"
      height="100%"
      bgcolor="white"
      style={{ overflowY: "auto" }}
    >
      <p className="font-extrabold text-white">{section}</p>

      {/* First Section */}
      <Box
        width={{ xs: "90%", sm: "70%", md: "60%", lg: "60%" }}
        maxWidth="1200px"
        maxHeight="50vh"
        p={2}
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        gap="25px"
        mb={4}
      >
        <ProgressCircles />
        <NewBubbleComponent scoreData={dataset} />
      </Box>
      {/* <Box
        width="1000px" 
      >
        <NewBubbleComponent  scoreData={dataset} />
      </Box> */}
      <button
        className="next-buttonScore"
        variant="contained"
        onClick={handleNext}
      >
        <span className="label">Next</span>
        <span className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill="currentColor"
              d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
            ></path>
          </svg>
        </span>
      </button>
      {/* Next Button */}
    </Box>
  );
};

export default CommonComponent;
