import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { scoreStore } from '../redux/store';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VerticalBarChart = ({ esg }) => {
  const [dataset, setDataset] = useState(0);

  useEffect(() => {
    const dataSet = scoreStore.getState();
    setDataset(dataSet);
  }, []);

    let data = {
      labels: ['E1', 'E2', 'E3', 'G1', 'G2', 'G3', 'S1', 'S2', 'S3'],
      datasets: [
        {
          label: 'Confidence',
          data: [
            dataset.e1_conf ? dataset.e1_conf  : ""  , 
            dataset.e2_conf ? dataset.e2_conf  : ""  , 
            dataset.e3_conf ? dataset.e3_conf  : ""  , 
            dataset.g1_conf ? dataset.g1_conf  : ""  , 
            dataset.g2_conf ? dataset.g2_conf  : ""  , 
            dataset.g3_conf ? dataset.g3_conf  : ""  , 
            dataset.s1_conf ? dataset.s1_conf  : ""  , 
            dataset.s2_conf ? dataset.s2_conf  : ""  , 
            dataset.s3_conf ? dataset.s3_conf  : ""  , 
            
          ],
          backgroundColor: 'rgba(137, 153, 151, 0.5)"',
        },
        {
          label: 'Performance',
          data: [
            dataset.e1_perf ? dataset.e1_perf : "", 
            dataset.e2_perf ? dataset.e2_perf : "",
            dataset.e3_perf ? dataset.e3_perf : "",
            dataset.g1_perf ? dataset.g1_perf : "",
            dataset.g2_perf ? dataset.g2_perf : "",
            dataset.g3_perf ? dataset.g3_perf : "",
            dataset.s1_perf ? dataset.s1_perf : "",
            dataset.s2_perf ? dataset.s2_perf : "",
            dataset.s3_perf ? dataset.s3_perf : "",
          ],
          backgroundColor: 'rgba(163, 193, 173,0.5)',
        },
        {
          label: "Weight",
          data: [
            dataset.e1_weight? dataset.e1_weight : "" , 
            dataset.e2_weight? dataset.e2_weight : "" , 
            dataset.e3_weight? dataset.e3_weight : "" , 
            dataset.g1_weight? dataset.g1_weight : "" , 
            dataset.g2_weight? dataset.g2_weight : "" , 
            dataset.g3_weight? dataset.g3_weight : "" , 
            dataset.s1_weight? dataset.s1_weight : "" , 
            dataset.s2_weight? dataset.s2_weight : "" , 
            dataset.s3_weight? dataset.s3_weight : "" , 

          ],
          backgroundColor : `rgba(229, 255, 252)`
        }
      ],
    };
    let options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        
      },
      scales: {
        y: {
          min: 0,
          max: 12,
        },
      },
    };
  
  return <Bar data={data} options={options} />;
};

export default VerticalBarChart;
