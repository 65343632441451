import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Paper,
  Tooltip,
  Box,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoIcon from "@mui/icons-material/Info";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
// import { Tooltip, IconButton } from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";

import {
  gridStore,
  mapStore,
  qTitleStore,
  answerStore,
  DeletedPlaceStore,
} from "../redux/store";
import ChoroplethMap from "./choropleth";
import CountryDropdown from "./CountryDropdown";
import * as CountryData from "./countries";
import { mapQuestions } from "../utils/utils";
import { useSearchParams } from "react-router-dom";

const formStyles = {
  container: {
    marginTop: "-40px",
    padding: "20px",
    backgroundColor: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: "20px",
    borderRadius: "15px",
    overflow: "hidden",
    backgroundColor: "#E5FFFC",
    flexGrow: 1,
    width: "100%",
    maxWidth: "100%",
    margin: "0 auto",
  },
};

const CustomRadio = ({ checked, onChange }) => (
  <IconButton onClick={onChange} size="small">
    {checked ? (
      <RadioButtonCheckedIcon sx={{ color: "#2B675C" }} />
    ) : (
      <RadioButtonUncheckedIcon />
    )}
  </IconButton>
);

const GridQuestion = ({currentQuestion}) => {
  const [showMap, setShowMap] = useState(false);
  const [options, setOptions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectionMode, setSelectionMode] = useState("regions");
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  function getVALS(data) {
    let selectedCols
    if(data){
       selectedCols = Object.keys(data);
    }else{
      return  { 
        columnsSelected : [], 
        selectedOption : {}, 
        mode : '' 
      }
    }
    const transformedData = {};
  
    const regions = [
      "NAC", "WNS", "Oceania", "MENA", "EEC", "ESE", "LAC", "SA", "SSA"
    ];
  
    let mode = "countries"; // Default mode
  
    // Loop through the data and transform it
    for (const country in data) {
      // Check if the value is an object or a direct string
      if (typeof data[country] === 'object') {
        transformedData[country] = Object.values(data[country])[0]; // Handle nested object case
      } else {
        transformedData[country] = data[country]; // Handle direct string case
      }
    }
  
    // Check if any selected column matches a region
    const hasRegion = selectedCols.some(col => regions.includes(col));
  
    if (hasRegion) {
      mode = "regions";
    }
    return { columnsSelected :  selectedCols, selectedOption : transformedData, mode };
  }
  useEffect(() => {
    if (showMap) {
      // const currentQuestion = qTitleStore.getState();
      const data = localStorage.getItem(
        `questionnareData-${searchParams.get("id")}`
      );
      if (data) {
        const parsed = JSON.parse(data)
        const arguement = parsed[currentQuestion]?.answers
        const { columnsSelected, selectedOption, mode } = getVALS(arguement);
        if (selectedOption && columnsSelected && mode) {
          setSelectedPlaces(columnsSelected);
          setColumns(columnsSelected);
          // setOptions(columnsSelected)
          setSelectedOptions(selectedOption);
          setSelectionMode(mode);
          answerStore.dispatch({
            type: "answer_object",
            payload: selectedOption,
          });
          if (mode == "regions") {
            answerStore.dispatch({
              type: "answer_object",
              payload: selectedOption,
            });
          } else if (mode == "countries") {
            setSelectedOptions(selectedOption);
            const regions = [
              "NAC",
              "WNS",
              "Oceania",
              "MENA",
              "EEC",
              "ESE",
              "LAC",
              "SA",
              "SSA",
            ];
            for (let key in selectedOption) {
              regions.forEach((region) => {
                setSelectedOptions(selectedOption);
                if (CountryData[region] && CountryData[region].includes(key)) {
                  answerStore.dispatch({
                    type: "answer_object",
                    payload: {
                      ...answerStore.getState(),
                      [key]: { [region]: selectedOption[key] },
                    },
                  });
                }
              });
            }
          }
        }
        console.log("Loaded from localStorage:", selectedOption);
      }
    } else {
      const data = localStorage.getItem(
        `questionnareData-${searchParams.get("id")}`
      );
      if (data) {
        const { selectedOption } = JSON.parse(data);
        if (selectedOption) {
          setSelectedOptions(selectedOption);
        }
      }
    }
  }, [qTitleStore.getState(), options, showMap, title]); // Reloads whenever the current question changes

  useEffect(() => {
    if (showMap) {
      mapStore.dispatch({
        type: "UPDATE",
        payload: {
          id: qTitleStore.getState(),
          data: answerStore.getState(),
        },
      });
    }
  }, [showMap, selectedOptions]);

  useEffect(() => {
    setColumns(selectedPlaces);
  }, [selectedPlaces]);

  const handleOptionChange = (column, option) => {
    if (selectedOptions[column] === option) {
      let answer = answerStore.getState();
      delete answer[column];
      answerStore.dispatch({
        type: "answer_object",
        payload: answer,
      });
      DeletedPlaceStore.dispatch({
        type: "DELETE_PLACE",
        payload: column,
      });
    } else {
      DeletedPlaceStore.dispatch({
        type: "DELETE_PLACE",
        payload: "",
      });


      // Dispatching the updated answer depending on the region or country
      if (selectionMode === "countries") {
        const regions = [
          "NAC",
          "WNS",
          "Oceania",
          "MENA",
          "EEC",
          "ESE",
          "LAC",
          "SA",
          "SSA",
        ];
        const data = answerStore.getState()
        Object.keys(data).forEach((element)=>{
          if(regions.includes(element)){
            delete data[element]
          }
        })
        regions.forEach((region) => {
          if (CountryData[region] && CountryData[region].includes(column)) {
            answerStore.dispatch({
              type: "answer_object",
              payload: {
                ...data,
                [column]: { [region]: option },
              },
            });
          }
        });
      } else if (selectionMode === "regions") {
        Object.keys(selectedOptions).forEach((element) => {
          if (!regions.includes(element)) {
            delete selectedOptions[element]; // Remove elements not in regions
          }
        });
        answerStore.dispatch({
          type: "answer_object",
          payload: { ...selectedOptions, [column]: option },
        });
      }
    }

    // Updating local selectedOptions state and syncing with localStorage
    setSelectedOptions((prevState) => {
      const newState = { ...prevState }; // Copy previous state
    
      // Handle selection logic
      if (newState[column] === option) {
        delete newState[column];  // Uncheck if already selected
      } else {
        newState[column] = option;  // Add new option
      }
    
      
      // Save to localStorage
      // const currentQuestion = qTitleStore.getState();
      const dataFromLocal = JSON.parse(localStorage.getItem(`questionnareData-${searchParams.get("id")}`))
      if(dataFromLocal){
        if(selectionMode === "regions"){
          Object.keys(newState).forEach(element => {
            if(!regions.includes(element)){
              delete newState[element]
            }
          })
        }else if(selectionMode === "countries"){
          Object.keys(newState).forEach(element => {
            if(regions.includes(element)){
              delete newState[element]
            }
          })
        }
        dataFromLocal[currentQuestion].answers = newState
      }
      localStorage.setItem(`questionnareData-${searchParams.get("id")}`,JSON.stringify(dataFromLocal))
      // localStorage.setItem(
      //   `${qTitleStore.getState()}-${searchParams.get("id")}`,
      //   JSON.stringify({
      //     columnsSelected: columns,
      //     selectedOption: newState,
      //     mode: selectionMode,
      //   })
      // );
      
      return newState;  // Return the updated state
    });
    
    
  };

  const getCellBackgroundColor = (column, option) => {
    if (selectedOptions) {
      return selectedOptions[column] === option ? "#B1FFE8" : "white";
    }
    return "white";
  };

  function initialClearence(callback) {
    if (Object.keys(selectedOptions).length != 0) {
      setSelectedOptions({});
    }

    callback();
  }

  useEffect(() => {
    initialClearence(() => {
      let data = localStorage.getItem(
        `questionnareData-${searchParams.get("id")}`
      );
      if (data) {
        let  selectedOption  = JSON.parse(data)[currentQuestion].answers;
        const values = getVALS(selectedOption)
        if (!selectedOption) {
          setSelectedOptions({});
        } else {
          setSelectedOptions(values.selectedOption);
        }
      } else {
        setSelectedOptions({});
      }
    });

    const unsubscribe = gridStore.subscribe(() => {
      const state = gridStore.getState();
      setTitle(state.itemTitle);
      setOptions(state.options);
      if (showMap) {
        setColumns([]);
      } else {
        setColumns(state.columns);
      }
      setRegions(state.columns);
    });
    return () => unsubscribe();
  }, [options]); // Reloads whenever the options change4

  useEffect(() => {
    const unsubscribe = qTitleStore.subscribe(() => {
      const currentQuestion = qTitleStore.getState();
      setShowMap(mapQuestions.includes(currentQuestion));
    });

    return () => unsubscribe();
  }, []); // Only run once

  function getColumn(col) {
    const withTooltip = (
      <>
        {col}
        <br />
        <Tooltip title={col} arrow>
          <IconButton size="small" sx={{ ml: 1 }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    );

    if (col === "Primary sector (Mining, Agriculture)") {
      return withTooltip;
    } else if (col === "Secondary Sector (Manufacturing, Construction)") {
      return withTooltip;
    } else if (
      col === "Tertiary (Consulting, Education, Healthcare, Finance)"
    ) {
      return withTooltip;
    } else if (col === "Quaternary (IT, Comm, Media)") {
      return withTooltip;
    } else {
      return col;
    }
  }

  return (
    <Box sx={formStyles.container}>
      <Typography
        variant="h4"
        gutterBottom
        style={{
          fontFamily: "montserrat",
          fontWeight: 500,
          fontSize: "24px",
          lineHeight: "28px",
          textAlign: "center",
          marginBottom: "20px",
          color: "#A4A1A0",
        }}
      >
        {title}
      </Typography>
      <Paper style={{ ...formStyles.paper, fontFamily: "montserrat" }}>
        {showMap && (
          <CountryDropdown
            setSelectionMode={setSelectionMode}
            setSelectedPlaces={setSelectedPlaces}
            currentQuestion={currentQuestion}
          />
        )}
        <Box sx={{ overflowX: "auto" }}>
          <Table
            style={{
              fontFamily: "montserrat",
              borderCollapse: "collapse",
              width: "100%",
              tableLayout: "fixed",
              borderRadius: "10px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "montserrat",
                    color: "#444444",
                    fontSize: "13px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    padding: "15px",
                  }}
                ></TableCell>
                {options.map((option, i) => (
                  <TableCell
                    key={i}
                    align="center"
                    style={{
                      fontFamily: "montserrat",
                      color: "#444444",
                      fontSize: "13px",
                      lineHeight: "18px",
                      border: "1px solid #ccc",
                      textAlign: "center",
                      wordWrap: "break-word",
                    }}
                  >
                    {option}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {columns.map((column, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      fontFamily: "montserrat",
                      borderBottom: "1px solid #ccc",
                      color: "#444444",
                      fontSize: "14px",
                      borderRight: "1px solid #ccc",
                      borderLeft: "1px solid #ccc",
                      textAlign: "center",
                      padding: "10px",
                      wordWrap: "break-word",
                    }}
                  >
                    {getColumn(column)}
                  </TableCell>
                  {options.map((option, i) => (
                    <TableCell
                      key={`${column}-${i}`}
                      align="center"
                      style={{
                        fontFamily: "montserrat",
                        borderBottom: "1px solid #ccc",
                        color: "#444444",
                        fontWeight: "bold",
                        fontSize: "12px",
                        borderRight: "1px solid #ccc",
                        borderLeft: "1px solid #ccc",
                        textAlign: "center",
                        padding: "10px",
                        wordWrap: "break-word",
                        backgroundColor: getCellBackgroundColor(column, option),
                      }}
                    >
                      <CustomRadio
                        checked={
                          selectedOptions[column] === option
                        }
                        onChange={() => handleOptionChange(column, option)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
      {showMap && <ChoroplethMap  />}
    </Box>
  );
};

export default GridQuestion;