import axios from "axios";

const url = "http://35.179.101.139:5000/";

//auth functions

/** Sign up new user. Password2 is the confirm password field. Both should be same */
export const signup = async (username, password1, password2, orgKey) => {
  let data = JSON.stringify({
    username: username,
    password1: password1,
    password2: password2,
    org_key: orgKey,
  });
  try {
    let res = await axios.post(url + "/signup", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

/** Login the user */
export const login = async (username, password, orgKey) => {
  let data = JSON.stringify({
    username: username,
    password: password,
    org_key: orgKey,
  });

  try {
    let res = await axios.post(url + "/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

/** Logut the user, alternatively just redirect to '/' */
export const logout = async () => {
  try {
    await axios.get(url + "/logout");
  } catch (e) {
    return e;
  }
};

//crud functions

/**Return all questionnaires available in db for the logged in user */
export const getQList = async (jwtToken) => {
  try {
    let res = await axios.get(url + "/qlist", {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

/**Get questionnare by its id, can be accessed even if the user does not have access */
export const getQByQID = async (id, jwtToken) => {
  try {
    let res = await axios.get(`${url}/q/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

/**Get a new questionnare for the user */
export const getNewQ = async (jwtToken) => {
  try {
    let res = await axios.get(`${url}/q/new`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

export const saveAnswer = async (qid, jwtToken, payload) => {
  try {
    let res = await axios.post(`${url}/ans/${qid}`, payload, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

export const saveAndNext = async (jwtToken, payload) => {
  let res = await axios.post(`${url}/next`, payload, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
};

export const sendReportData = async (reportData, jwtToken) => {
  let res = await axios.post(`${url}/report`, reportData, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
};

export const getAllReports = async (jwt) => {
  let res = await axios.get(`${url}/report/all`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  return res.data;
};

//CSV functions
export const createAndDownloadCSV = (data, filename) => {
  console.log(data);
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  }
};

export const convertItemsToCSV = (questions) => {
  let output_array = [];
  const columns = {
    conf: [],
    e1_conf: [],
    e1_conf_limit: [],
    e1_perf: [],
    e1_weight: [],
    e2_conf: [],
    e2_conf_limit: [],
    e2_perf: [],
    e2_weight: [],
    e3_conf: [],
    e3_conf_limit: [],
    e3_perf: [],
    e3_weight: [],
    g1_conf: [],
    g1_conf_limit: [],
    g1_perf: [],
    g1_weight: [],
    g2_conf: [],
    g2_conf_limit: [],
    g2_perf: [],
    g2_weight: [],
    g3_conf: [],
    g3_conf_limit: [],
    g3_perf: [],
    g3_weight: [],
    perf: [],
    s1_conf: [],
    s1_conf_limit: [],
    s1_perf: [],
    s1_weight: [],
    s2_conf: [],
    s2_conf_limit: [],
    s2_perf: [],
    s2_weight: [],
    s3_conf: [],
    s3_conf_limit: [],
    s3_perf: [],
    s3_weight: [],
  };
  questions.forEach((question, qIdx) => {
    Object.keys(columns).forEach((column, cIdx) => {
      if (question[column] === undefined) {
        question[column] = "";
      }
      output_array.push({
        index: qIdx + 1,
        column: column,
        value: question[column],
      });
    });
  });
  const new_txt = objArrayToCSV(output_array);
  createAndDownloadCSV(new_txt, "question_scores.tsv");
};

export const objArrayToCSV = (objArray) => {
  if (!objArray || objArray.length === 0) return "";

  let lines_txt = [];
  let scoreKeys = new Set();

  // Predefined score-related keys based on the `convertItemsToCSV` function
  const predefinedKeys = [
    "conf",
    "e1_conf",
    "e1_conf_limit",
    "e1_perf",
    "e1_weight",
    "e2_conf",
    "e2_conf_limit",
    "e2_perf",
    "e2_weight",
    "e3_conf",
    "e3_conf_limit",
    "e3_perf",
    "e3_weight",
    "g1_conf",
    "g1_conf_limit",
    "g1_perf",
    "g1_weight",
    "g2_conf",
    "g2_conf_limit",
    "g2_perf",
    "g2_weight",
    "g3_conf",
    "g3_conf_limit",
    "g3_perf",
    "g3_weight",
    "perf",
    "s1_conf",
    "s1_conf_limit",
    "s1_perf",
    "s1_weight",
    "s2_conf",
    "s2_conf_limit",
    "s2_perf",
    "s2_weight",
    "s3_conf",
    "s3_conf_limit",
    "s3_perf",
    "s3_weight",
  ];

  // Add predefined score keys to the set
  predefinedKeys.forEach((key) => scoreKeys.add(key));

  // Define the headers: question, id, answer (as JSON), and all score keys
  const headers = ["question", "id", "answer", ...Array.from(scoreKeys)];
  lines_txt.push(headers.join("\t"));

  // To store the previous scores for calculating differences
  let previousScores = {};

  // Generate rows for each object in the array
  objArray.forEach((obj) => {
    const { question, id, answer, score } = obj;

    // Calculate the differences for score fields
    const diffScores = {};
    Array.from(scoreKeys).forEach((key) => {
      const currentValue = score?.[key] ?? 0; // Use 0 if the field is missing
      const previousValue = previousScores[key] ?? 0; // Use 0 if no previous value
      diffScores[key] = currentValue - previousValue;
    });

    // Update the previousScores for the next iteration
    previousScores = { ...score };

    // Build the row
    const row = headers.map((key) => {
      if (key === "question") return question;
      if (key === "id") return id;
      if (key === "answer") return JSON.stringify(answer); // Stringify the `answers` object
      return diffScores[key] ?? ""; // Use the difference values for score keys
    });

    lines_txt.push(row.join("\t"));
  });

  // Join all lines with a newline character
  return lines_txt.join("\n");
};
