import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Chip,
  Box,
  ListSubheader,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import * as CountryData from "./countries";
import { qTitleStore } from "../redux/store";
import { useSearchParams } from "react-router-dom";

const formatCountryData = () => {
  return Object.entries(CountryData).map(([region, countries]) => ({
    region,
    countries: countries.map((country) => ({ name: country, region })),
  }));
};

const CountryDropdown = ({ setSelectionMode, setSelectedPlaces, col }) => {
  const [selectionType, setSelectionType] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [open, setOpen] = useState(false);
  let [searchParamss] = useSearchParams();

  const handleTypeChange = (event) => {
    setSelectionType(event.target.value);
    setSelectionMode(event.target.value);
    setSelectedItems([]);
    setOpen(true);
  };

  const handleItemSelect = (event,items) => {
    const value = event.target.value;
    if (selectionType === "region") {
      setSelectedRegions((prevRegions) => {
        const updatedRegions = [...prevRegions, ...value]; // Spread the array of values if multiple
        // setSelectedPlaces(updatedRegions);
        return updatedRegions;
      });
    } else if (selectionType === "countries") {
      setSelectedCountries((prevCountries) => {
        const updatedCountries = [...prevCountries, ...value]; // Spread the array of values if multiple
        // setSelectedPlaces(updatedCountries);
        return updatedCountries;
      });
    }
      setSelectedItems(value); // Ensure this is treated as an array
      const newArr = [...value]
      setSelectedPlaces(newArr)
  };

  useEffect(() => {
    if(localStorage.getItem(`3rdMAP/${searchParamss.get("id")}`)){
      let arrPrevSelcted = Object.keys(JSON.parse(localStorage.getItem(`3rdMAP/${searchParamss.get("id")}`)))
      if(selectedItems.length <= 0){
        setSelectedPlaces([ ...arrPrevSelcted])
      }
      setSelectedItems(Object.keys(JSON.parse(localStorage.getItem(`3rdMAP/${searchParamss.get("id")}`))));
    }else{
      setSelectedPlaces(selectedItems);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = qTitleStore.subscribe(() => {
      setSelectedItems([]);
    });
  }, []);

  const handleBackToSelection = () => {
    setSelectionType("");
    setSelectedItems([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const getValue = (region) => {
    const regionMap = {
      NAC: "North America and the Caribbean",
      WNS: "Western North and South",
      EEC: "Eastern European Countries",
      ESE: "Eastern and Southern Europe",
      LAC: "Latin America and the Caribbean",
      MENA: "Middle East and North Africa",
      SA: "South Asia",
      SSA: "Sub-Saharan Africa",
      OCE: "Oceania",
    };
    return regionMap[region] || "Oceania";
  };
  const renderDropdown = () => {

    const formattedData = formatCountryData();
    const cont = formatCountryData().flatMap(({ countries }) => countries);
    cont.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
    const items =  cont 
    return (
      <Select
        multiple
        value={selectedItems}
        onChange={(e)=>{handleItemSelect(e,items)}}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        displayEmpty
        renderValue={(selected) => {
          if(selected.length === 0){
            return ( <p> Choose Countries </p> )
          }
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          );
        }}
        sx={{ width: "100%", marginBottom: 2 }}
      >
        <ListSubheader>
          <MenuItem
            // onClick={handleBackToSelection}
            sx={{ color: "primary.main" }}
          >
             Select Countries
          </MenuItem>
        </ListSubheader>
           { 
           items.map(({ name, region }) => (
              <MenuItem key={name} value={name}>
                <Box display="flex" alignItems="center" width="100%">
                  <span>{name}</span>
                  <Box flexGrow={1} />
                  <Tooltip title={`Region: ${region}`} arrow>
                    <IconButton size="small" sx={{ ml: 1 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </MenuItem>
            ))}
      </Select>
    );
  };

  return renderDropdown();
};

export default CountryDropdown;