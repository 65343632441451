import { createStore } from "redux";

const jwtReducer = (state, action) => {
  state = action.payload;
  return state;
};

const qReducer = (state, action) => {
  state = action.payload;
  return state;
};

const gridReducer = (state, action) => {
  state = action.payload;
  return state;
};

const answerRequestReducer = (state, action) => {
  state = action.payload;
  return state;
};

const answerReducer = (state, action) => {
  state = action.payload;
  return state;
};

const scoreReducer = (state, action) => {
  state = action.payload;
  return state;
};

const bubbleReducer = (state, action) => {
  state = action.payload;
  return state;
};

const qTitleReducer = (state, action) => {
  state = action.payload;
  return state;
};

const mapDataReducer = (state = {}, action) => {
  switch (action.type) {
    case "ADD":
      return { ...state, [action.payload.id]: action.payload.data };

    case "UPDATE":
      return {
        ...state,
        [action.payload.id]: action.payload.data,
      };

    default:
      return state;
  }
};

const defaultReportReducer = (state, action) => {
  state = action.payload;
  return state;
};

const defaultPromptsReducer = (state, action) => {
  state = action.payload;
  return state;
};

const deletedPlaceReducer = (state, action) => {
  state = action.payload;
  return state;
};
const botChatReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      // Ensure the state remains an array and append new messages correctly
      return [...state, { text: action.payload[0], sender: action.payload[1] }];
    
    case 'CLEAR_CHAT':
      return [];
    
    default:
      return state;
  }
};


export const jwtStore = createStore(jwtReducer);
export const qStore = createStore(qReducer);
export const gridStore = createStore(gridReducer);
export const answerStore = createStore(answerRequestReducer);
export const solnStore = createStore(answerReducer);
export const scoreStore = createStore(scoreReducer);
export const bubbleStore = createStore(bubbleReducer);
export const mapStore = createStore(mapDataReducer);
export const qTitleStore = createStore(qTitleReducer);
export const ReportStore = createStore(defaultReportReducer);
export const PromptStore = createStore(defaultPromptsReducer);
export const DeletedPlaceStore = createStore(deletedPlaceReducer);
export const chatBotStore = createStore(botChatReducer) 
