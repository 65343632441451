import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { scoreStore } from "../redux/store";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const RadarChart = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    let dataSet = scoreStore.getState();
    setData({
      labels: ["E1", "E2", "E3", "G1", "G2", "G3", "S1", "S2", "S3"],
      datasets: [
        {
          label: "Confidence",
          data: [
            dataSet.e1_conf,
            dataSet.e2_conf,
            dataSet.e3_conf,
            dataSet.g1_conf,
            dataSet.g2_conf,
            dataSet.g3_conf,
            dataSet.s1_conf,
            dataSet.s2_conf,
            dataSet.s3_conf,
          ],
          backgroundColor: "rgba(137, 153, 151, 0.5)", // Laurel Green with opacity
          borderColor: "#A0B2B0", // Laurel Green
          pointBackgroundColor: "#A0B2B0",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#A3C1AD",
        },
        {
          label: "Performance",
          data: [
            dataSet.e1_perf,
            dataSet.e2_perf,
            dataSet.e3_perf,
            dataSet.g1_perf,
            dataSet.g2_perf,
            dataSet.g3_perf,
            dataSet.s1_perf,
            dataSet.s2_perf,
            dataSet.s3_perf,
          ],
          backgroundColor: "rgba(163, 193, 173,0.5)", // Brunswick Green with opacity
          borderColor: "#72807E", // Brunswick Green
          pointBackgroundColor: "#72807E",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#224D3E",
        },
        {
          label: "Weight",
          data: [
            dataSet.e1_weight,
            dataSet.e2_weight,
            dataSet.e3_weight,
            dataSet.g1_weight,
            dataSet.g2_weight,
            dataSet.g3_weight,
            dataSet.s1_weight,
            dataSet.s2_weight,
            dataSet.s3_weight,
          ],
          backgroundColor: "rgba(229, 255, 252,0.5)", // Darker Green with opacity
          borderColor: "#CEE6E3", // Darker Green
          pointBackgroundColor: "#CEE6E3",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#0A401C",
        },
      ],
    });
  }, []);

  const options = {
    scale: {
      ticks: { beginAtZero: true },
      gridLines: {
        color: "rgba(0, 0, 0, 0.1)", // Light grid lines
      },
    },
    elements: {
      line: {
        borderWidth: 2, // Keep only dataset borders visible
      },
    },
    plugins: {
      legend: {
        labels: {
          color: "#333", // Text color for better contrast
        },
      },
    },
  };

  return data["datasets"] ? <Radar data={data} options={options} /> : <></>;
};

export default RadarChart;