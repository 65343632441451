import React, { useEffect, useRef } from 'react';
import { Bubble } from 'react-chartjs-2';
import { Chart as ChartJS, plugins, registerables } from 'chart.js';
// import './chartImage.png'; // Correct path to your image file
import './formAfter.css'

ChartJS.register(...registerables);



const NewBubbleComponent = ({ scoreData, optionsData }) => {

const chartRef = useRef(null);

  const data = {
    datasets: [
        {
          label: "E1",
          data: [
            {
              x: scoreData?.e1_perf,
              y: scoreData?.e1_conf,
              r: Math.floor(scoreData?.e1_weight) > 2 ? 10 : Math.floor(scoreData?.e1_weight) *5,
            },
          ],
          backgroundColor: "rgb(217, 234, 211)",
 
        },
        {
          label: "E2",
          data: [
            {
              x: scoreData?.e2_perf,
              y: scoreData?.e2_conf,
              r: Math.floor(scoreData?.e2_weight) > 2 ? 10 : Math.floor(scoreData?.e2_weight) * 5,
            },
          ],
          backgroundColor: "rgb(211, 235, 228)",
        },
        {
          label: "E3",
          data: [
            {
              x: scoreData?.e3_perf,
              y: scoreData?.e3_conf,
              r: Math.floor(scoreData?.e3_weight) > 2 ? 10 : Math.floor(scoreData?.e3_weight) * 5 ,
            },
          ],
          backgroundColor: "rgb(235, 228, 211)",
        },
        {
          label: "G1",
          data: [
            {
              x: scoreData?.g1_perf,
              y: scoreData?.g1_conf,
              r: Math.floor(scoreData?.g1_weight) > 2 ? 10 : Math.floor(scoreData?.g1_weight)*5,
            },
          ],
          backgroundColor: "rgb(217, 210, 233)",
        },
        {
          label: "G2",
          data: [
            {
              x: scoreData?.g2_perf,
              y: scoreData?.g2_conf,
              r: Math.floor(scoreData?.g2_weight) > 2 ? 10 : Math.floor(scoreData?.g2_weight) *5,
            },
          ],
          backgroundColor: "rgb(221, 217, 232)",
        },
        {
          label: "G3",
          data: [
            {
              x: scoreData?.g3_perf,
              y: scoreData?.g3_conf,
              r: Math.floor(scoreData?.g3_weight) > 2 ? 10 :Math.floor(scoreData?.g3_weight) *5,
            },
          ],
          backgroundColor: "rgb(227, 224, 232)"
        },
        {
          label: "S1",
          data: [
            {
              x: scoreData?.s1_perf,
              y: scoreData?.s1_conf,
              r: Math.floor(scoreData?.s1_weight) > 2 ? 10 : Math.floor(scoreData?.s1_weight) *5,
            },
          ],
          backgroundColor: "rgb(255, 242, 204)",
        },
        {
          label: "S2",
          data: [
            {
              x: scoreData?.s2_perf,
              y: scoreData?.s2_conf,
              r: Math.floor(scoreData?.s2_weight) > 2 ? 10 : Math.floor(scoreData?.s2_weight) *5,
            },
          ],
          backgroundColor: "rgb(255, 247, 221)",
        },
        {
          label: "S3",
          data: [
            {
              x: scoreData?.s3_perf,
              y: scoreData?.s3_conf,
              r: Math.floor(scoreData?.s3_weight) > 2 ? 10 : Math.floor(scoreData?.s3_weight) *5,
            },
          ],
          backgroundColor: "rgb(255, 251, 238)",
        },
        {
          label: "overall",
          data: [{ x: scoreData?.perf, y: scoreData?.conf, r: 20 }],
          backgroundColor: "rgb(201, 203, 207)",
        },
      ],
    backgroundColor: 'gradient',
    borderColor: 'rgb(0, 0, 0)',
    borderWidth: 2,
  };

  const options = {
    plugins: true, 
    maintainAspectRatio: false,
    scales: {
      x: {
        
        title: {
          display: true,
          text: 'performance',
        },
        ticks: {
          stepSize: 4,
          callback: (value) => {
            if (value === 0) return `${value} \n \n Lagging`;
            if (value >= 0 && value <= 4) return `${value}`;
            if (value >= 4 && value <= 12) return `${value}`;
            if (value === 12) return 'Authentic';
            return 'Authentic';
          },
        },
      },
      y: {
        min: 0,
        title: {
          display: true,
          text: 'confidence',
        },
        ticks: {
          stepSize: 4,
          callback: (value) => {
            if (value >= 0 && value <= 4) return `${value}`;
            if (value >= 4 && value < 12) return `${value}`;
            if (value === 12) return `${value}`;
            return 'Confidence';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: (${context.raw.x}, ${context.raw.y})`;
          },
        },
      },    
    },
  };
  const customPlugin = {
    id: 'customPlugin',
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;

      if (!ctx) {
        console.error('Canvas context is not available.');
        return;
      }

      data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex);

        meta.data.forEach((dataPoint, index) => {
          const { x, y } = dataPoint.tooltipPosition();

          // Draw shadow
          ctx.save();
          ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 3;
          ctx.shadowOffsetY = 3;
          ctx.beginPath();
          const radius = dataset.data[index].r || 5;
          ctx.arc(x, y, radius, 0, Math.PI * 2);
          ctx.fillStyle = dataset.backgroundColor || 'rgba(0, 0, 0, 0.1)';
          ctx.fill();
          ctx.restore();

          // Draw text inside bubble
          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(dataset.label || '', x, y);
          ctx.restore();
        });
      });
    },
  };
  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current;
      chartInstance.update();
    }
  }, [scoreData]);
  return (
    <div> 
      <div className="bubbleBoxScore"  style={{height: "35em"}}>
        <Bubble ref={chartRef} width="578px" data={data} options={options} plugins={[customPlugin]}/>
      </div>
    </div>
  );
};

export default NewBubbleComponent;
